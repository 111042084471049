import loadable from '@loadable/component';
// import { lazy } from 'react';
// const isSSR = typeof window === 'undefined';
// const loader = isSSR ? loadable : lazy;
// prettier-ignore
export default {
  'legacy/drawer-about-investors': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-about-investors')),
  'legacy/drawer-about-offices': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-about-offices')),
  'legacy/drawer-alternating-2up': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-alternating-2up')),
  'legacy/drawer-alternating-2up-cta': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-alternating-2up-cta')),
  'legacy/drawer-card-grid-2-3-cols': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-card-grid-2-3-cols')),
  'legacy/drawer-cards': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards')),
  'legacy/drawer-cards-enhanced': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards-enhanced')),
  'legacy/drawer-cards-2up': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards-2up')),
  'legacy/drawer-cards-2up-cards': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards-2up-cards')),
  'legacy/drawer-case-study-teaser': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-case-study-teaser')),
  'legacy/drawer-cta-simple': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cta-simple')),
  'legacy/drawer-columns-3up': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-columns-3up')),
  'legacy/drawer-common-logos': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-common-logos')),
  'legacy/drawer-community-join': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-community-join')),
  'legacy/drawer-content-cta': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-content-cta')),
  'legacy/drawer-ctas': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-ctas')),
  'legacy/drawer-enterprise-card': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-enterprise-card')),
  'legacy/drawer-expandable-content': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-expandable-content')),
  'legacy/drawer-faqs': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-faqs')),
  'legacy/drawer-feature-article': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-feature-article')),
  'legacy/drawer-feature-cta-image': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-feature-cta-image')),
  'legacy/drawer-feature-blog': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-feature-blog')),
  'legacy/drawer-featured-news': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-featured-news')),
  'legacy/drawer-features-card': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-features-card')),
  'legacy/drawer-grid': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-grid')),
  'legacy/drawer-hubspot-form': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-hubspot-form')),
  'legacy/drawer-cards-past-webinars': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards-past-webinars')),
  'legacy/drawer-cards-upcoming-events': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-cards-upcoming-events')),
  'legacy/drawer-icon-list': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-icon-list')),
  'legacy/drawer-job-board': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-job-board')),
  'legacy/drawer-latest-blogs': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-latest-blogs')),
  'legacy/drawer-life-at-rasa-blogs': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-life-at-rasa-blogs')),
  'legacy/drawer-logo-list': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-logo-list')),
  'legacy/drawer-logos-small': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-logos-small')),
  'legacy/drawer-logos': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-logos')),
  'legacy/drawer-mini-case-study': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-mini-case-study')),
  'legacy/drawer-product-comparison': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-product-comparison')),
  'legacy/drawer-product-pricing': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-product-pricing')),
  'legacy/drawer-product-pricing-new': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-product-pricing-new')),
  'legacy/drawer-product-pricing-new-v2': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-product-pricing-new-v2')),
  'legacy/drawer-research-collabs': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-research-collabs')),
  'legacy/drawer-research-topics': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-research-topics')),
  'legacy/drawer-showcase-teasers': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-showcase-teasers')),
  'legacy/drawer-tabbed-content': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-tabbed-content')),
  'legacy/drawer-team-card-list': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-team-card-list')),
  'legacy/drawer-team-list': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-team-list')),
  'legacy/drawer-teaser': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-teaser')),
  'legacy/drawer-image': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-image')),
  'legacy/drawer-video': loadable(() => import(/* webpackPrefetch: true */ './legacy/drawer-video')),
  'legacy/header-home': loadable(() => import(/* webpackPrefetch: true */ './legacy/header-home')),
  'legacy/header-page': loadable(() => import(/* webpackPrefetch: true */ './legacy/header-page')),
  'legacy/header-page-v2': loadable(() => import(/* webpackPrefetch: true */ './legacy/header-page-v2')),
  'legacy/header-video': loadable(() => import(/* webpackPrefetch: true */ './legacy/header-video')),
  'refactor/drawer-alternating-image-content': loadable(() => import(/* webpackPrefetch: true */ './refactor/drawer-alternating-image-content')),
  'refactor/drawer-content-with-cta': loadable(() => import(/* webpackPrefetch: true */ './refactor/drawer-content-with-cta')),
  'refactor/drawer-feature-list-2cols': loadable(() => import(/* webpackPrefetch: true */ './refactor/drawer-feature-list-2cols')),
  'refactor/drawer-split-content-gallery': loadable(() => import(/* webpackPrefetch: true */ './refactor/drawer-split-content-gallery')),
  'refactor/header-with-image': loadable(() => import(/* webpackPrefetch: true */ './refactor/header-with-image')),
  'updates/drawer-statistics': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-statistics')),
  'updates/drawer-digital-journey': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-digital-journey')),
  'updates/drawer-image-below': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-image-below')),
  'updates/drawer-image-right': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-image-right')),
  'updates/drawer-case-study-teaser': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-case-study-teaser')),
  'updates/drawer-ctas': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-ctas')),
  'updates/drawer-featured-cta': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-featured-cta')),
  'updates/drawer-card-grid': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-card-grid')),
  'updates/drawer-cards-narrow': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-cards-narrow')),
  'updates/drawer-community': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-community')),
  'updates/header-home': loadable(() => import(/* webpackPrefetch: true */ './updates/header-home')),
  'updates/header-home-cover': loadable(() => import(/* webpackPrefetch: true */ './updates/header-home-cover')),
  'updates/drawer-columns-3up-fluid': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-columns-3up-fluid')),
  'updates/drawer-image-text-2up-with-callouts': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-image-text-2up-with-callouts')),
  'updates/drawer-logo-chain': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-logo-chain')),
  'updates/drawer-logo-chain-v2': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-logo-chain-v2')),
  'updates/drawer-testimonials': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-testimonials')),
  'updates/drawer-testimonials-v2': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-testimonials-v2')),
  'updates/drawer-news-bar': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-news-bar')),
  'updates/drawer-product-release-table': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-product-release-table')),
  'updates/drawer-hubspot-form': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-hubspot-form')),
  'updates/drawer-hubspot-form-v2': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-hubspot-form-v2')),
  'updates/drawer-hubspot-form-ctas': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-hubspot-form-ctas')),
  'updates/drawer-pdf-download': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-pdf-download')),
  'updates/drawer-case-study-overview': loadable(() => import(/* webpackPrefetch: true */ './updates/drawer-case-study-overview')),
};
